html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: sans-serif;
  background: rgb(33, 154, 241);
  background: linear-gradient(180deg, rgba(33, 154, 241, 1) 0%, rgba(0, 54, 94, 1) 100%);
}
